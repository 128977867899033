.loadingMarker {
  background: var(--red);
  padding: 7px 20px 4px;
  border-radius: 25px;
  margin: 0;
  line-height: 1.15;
}
/* === Down Arrow === */
.loadingMarker:before {
  content: '';
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  border-top: 14px solid var(--red);
  bottom: -9px;
  left: 50%;
  transform: translateX(-50%);
}
