.social {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0px;
  padding-bottom: 50px;
}

.social section {
  display: flex;
  gap: 40px;
}
