.checkIn {
  background: #f6f6f6;
}
.checkInWrap {
  padding: 20px;
}
.checkIn h1 {
  margin: 20px 0 10px;
}
.checkIn h2 {
  color: #536f85;
  font-family: 'Vollkorn';
  font-size: 24px;
  margin: 0 0 30px;
}
.checkIn h3 {
  color: #294b67;
  font-weight: 600;
  font-size: 18px;
}
.checkIn img {
  max-width: 100%;
}
.checkIn .buttonWrap {
  margin: 30px 0px;
}

.qrheader {
  color: #EE1C25;
  text-align: center;
  font-size: 40px;
  margin: 20px 0 10px;
}
.qr {
  margin-bottom: 20px;
  color: #030303;
}
.qr .numbered {
  display: flex;
  margin-left: 30px;
  margin-right: 30px;
}
.qr .centered {
  display: flex;
  margin-left: 30px;
  margin-right: 30px;
  
  max-width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
.url {
  color: #008F9B;
  display: flex;
  font-size: 20px;
  max-width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
.checkInStep {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;
  min-height: 30px;
  outline: none;
  background: #e5665d;
  font-size: 18px;
  border-radius: 30px;
  margin: 2px;
}

.checkInInstructions {
  font-size: 16px;
  display: flex;
  align-items: center;
  margin: 2px 0 2px 0;
}