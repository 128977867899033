.finalize {
  background: #e7eef0;
  padding: 15px 20px;
  text-align: center;
}

.fancyBar {
  background: #e5665d;
  margin: 20px auto;
  text-indent: -9999px;
  width: 32px;
  height: 2px;
  border-radius: 2px;
}

.button {
  margin: 20px;
}
