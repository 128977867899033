.nextAvailableMarker {
  position: relative;
  opacity: 1;
  cursor: pointer;
}
.nextAvailableMarker:active {
  opacity: 0.9;
}
.nextAvailableMarker button {
  border: none;
  cursor: pointer;
  background: none;
}
.nextAvailableMarker p {
  background: var(--red);
  color: #fff;
  padding: 10px 20px;
  border-radius: 25px;
  margin: 0;
  line-height: 0.9;
}
.nextAvailableMarker p small {
  display: block;
  text-wrap: nowrap;
  font-size: 0.6rem;
}
.nextAvailableMarker p cite {
  font-style: normal;
  font-weight: 700;
  font-size: 0.75rem;
}
/* === Down Arrow === */
.nextAvailableMarker:before {
  content: '';
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  border-top: 14px solid var(--red);
  bottom: -9px;
  left: 50%;
  transform: translateX(-50%);
}

/* === Selected === */
.selected p {
  background: var(--main);
}
.selected.nextAvailableMarker:before {
  border-top: 14px solid var(--main);
}
