.steps {
  display: flex;
  flex-direction: column;
}
.step {
  display: flex;
  flex-direction: row;
  gap: 25px;
}
/* === Number === */
.number {
  min-width: 36px;
  position: relative;
}
.number em {
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  border: 2px solid var(--border);
  border-radius: 100%;
  width: 36px;
  height: 36px;
  line-height: 1.5;
  text-align: center;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 50%;
  margin-left: -20px;
  background: var(--background2);
  z-index: 2;
}
.number em.selected {
  background: var(--red);
  color: var(--white);
  border: 2px solid var(--red);
}
.number span {
  text-indent: -9999px;
  background: #d4dbe0;
  width: 2px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 50%;
  margin-left: -1px;
  z-index: 1;
}

/* === Content === */
.content {
  flex-grow: 1;
  padding-bottom: 30px;
}
.step h3 {
  margin: 12px 0 10px !important;
}
.step p {
  line-height: 1.5;
}
