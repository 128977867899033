.uploadCard {
  background: #e7eef0;
  border-radius: 5px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 20px 0px;
}
.uploadCard h3 {
  margin: 0 0 30px;
}
.uploadCard p {
  margin: 0;
}

/* === Take a Photo Button === */
.uploadPhoto button p.button {
  background: var(--red);
  border-radius: 5px;
  padding: 10px 15px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: center;
}

.uploadPhoto button em {
  color: #fff;
  font-style: normal;
  font-size: 1rem;
}
.uploadPhoto button p.button span {
  padding-top: 5px;
}

/* === Video/Photo === */
.camera {
  border-radius: 5px;
  position: relative;
  width: 100%;
  height: 250px; /* Needs to match photoHeight in TakePhoto.tsx */
  margin: 0 0 20px;
  text-align: center;
}

.camera canvas,
.camera video {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5px;
  z-index: 1;
}
.camera canvas.visible,
.camera video.visible {
  z-index: 2;
}

/* === Browse Files Link === */
.uploadFile button label {
  display: block;
  margin: 30px 0 0;
}
.uploadFile button p.link {
  font-size: 1rem;
  color: var(--red);
  font-weight: 700;
}
.uploadFile input[type='file'] {
  display: none;
}
