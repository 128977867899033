.virtualCareBook {
  color: #294b67;
}

/* === Virtual Care === */
.bookTimes {
  padding: 20px;
}
.virtualCareTabSelector {
  padding: 10px 0px 20px;
  width: 100%;
}
.onDemandVisit {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}
.onDemandVisitDetails {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 15px;
}
.onDemandVisitDate {
  font-weight: 500;
  font-size: 16px;
}
.onDemandVisitDate span {
  font-size: 15px;
  font-weight: bold;
}
.onDemandNullState {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 20px 0;
}
.onDemandNullStateTitle {
  color: #e5665d;
  font-size: 14px;
  font-weight: 600;
}
.onDemandNullStateDescription {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 0 15px;
}
/* === Select Available Time Slots === */
.selectAvailableTimeSlots {
  text-align: center;
  padding-bottom: 10px;
}
/* === Book Times === */
.bookTimesContainer {
  padding: 30px 20px 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

h2.bookTimeDate {
  font-size: 18px;
  color: #294b67;
  font-weight: 600;
  margin: 50px auto 20px;
}

/* === Time Slots === */
.timeSlots {
  padding-inline: unset;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  margin: 0 0 20px;
  gap: 16px 8px;
  width: 100%;
}
.timeSlots li {
  /* Accounting for the gap, make each slot 1/4th */
  flex-basis: calc(25% - 6px);
  font-size: 11px;
}
.timeSlots li button {
  border: 1px solid #d4dbe0;
  border-radius: 5px;
  padding: 10px;
  font-weight: semi-bold;
  color: #294b67;
  width: 100%;
  font-size: 11px;
}
.timeSlots li button:hover {
  border: 1px solid #e5665d;
}
.timeSlots li.selected button {
  border: 1px solid #e5665d;
  background: #e5665d;
  border-radius: 5px;
  padding: 10px 0px;
  font-weight: 600;
  color: #fff;
}
.moreTimes {
  margin: 15px 0px 30px;
  text-align: center;
}
.moreTimes button {
  color: #e5665d;
  font-weight: 700;
  font-size: 13px;
  margin: 0 auto;
}

/* Basement */
.basement {
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Emergency */
.emergency {
  text-align: center;
  font-size: 13px;
}
