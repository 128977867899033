.paymentReceiptHeader {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #d4dbe0;
  text-align: center;
  padding-top: 30px;
}

.paymentReceiptHeader h1 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
  line-height: 35x;
}

.paymentReceiptHeaderLine {
  width: 100%;
  height: 2px;
  background-color: #e5665d;
  display: inline-block;
  position: relative;
  top: -8px;
}

.paymentReceiptHeaderAmount {
  font-size: 24px;
  font-weight: 700;
}

.paymentReceiptHeaderSubtitle {
  font-size: 14px;
  margin: 12px 0 20px;
  font-weight: 600;
}

.paymentReceipt {
  padding: 30px 30px 60px;
}

.paymentReceipt h2 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.paymentReceiptTable {
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  overflow: auto;
}

.paymentReceiptTable tbody td {
  vertical-align: middle;
}

.paymentReceiptTable tbody tr {
  border: 0.5px solid #efefef;
}

.paymentReceiptTableLabel {
  color: #94a5ab;
  font-weight: 600;
  font-size: 11px;
}

.paymentReceiptTableValue {
  font-size: 14px;
  font-weight: 500;
}

.paymentReceiptNullState {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}
