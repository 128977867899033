.insurance {
  padding: 20px;
}

.selfPay {
  padding: 20px;
}

.selfPay iframe {
  border: none;
  width: 100%;
  height: 460px;
  border-radius: 5px;
  border: 1px solid #d4dbe0;
}

.selfPayButton {
  margin: 20px 0 0;
}
