.signForms {
  padding: 20px;
}
.loading {
  text-align: center;
}

.signForms .button {
  margin: 20px 0 0;
}
