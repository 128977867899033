.clinicHours {
  padding: 0px 0px 20px;
  border-bottom: 1px solid #d4dbe0;
  margin-bottom: 20px;
}
.clinicHours h5 {
  color: #294b67;
  font-weight: 600;
  font-size: 18px;
  margin: 0 0 10px;
}
.clinicHours ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.clinicHours li {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #294b67;
  font-size: 13px;
  margin: 0;
  padding: 8px 12px;
  border-radius: 5px;
}
.clinicHours li p {
  flex-grow: 1;
  margin: 0;
}
.clinicHours li.selected {
  color: var(--green);
  background: #e7eef0;
}
.clinicHours em {
  font-style: normal;
}
