ol.pickList {
  list-style: none;
  margin: 0;
  padding: 0;
}
ol.pickList li {
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 700;
  color: var(--main);
  display: flex;
  justify-content: center;
  padding: 15px 20px;
  border: 2px solid var(--border);
  border-radius: 5px;
  margin: 0 0 10px;
}
ol.pickList li.selected {
  color: var(--red);
  border: 2px solid var(--red);
}
ol.pickList li:last-child {
  margin: 0;
}

ol.pickList li:active {
  opacity: 0.7;
}
