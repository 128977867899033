.datePicker {
  margin: 20px 0px;
}

.datePicker p {
  background: #e7eef0;
  color: #536f85;
  font-size: 11px;
  padding: 12px;
  text-align: center;
  border-radius: 5px;
  line-height: 1.3;
  margin: 10px 0px 30px;
}

/* === React Calendar === */
:global(.react-calendar) {
  width: 100%;
  max-width: 100%;
  background: none;
  border: none;
  font-weight: 600;
  line-height: 1.125em;
}
:global(.react-calendar--doubleView) {
  width: 700px;
}
:global(.react-calendar--doubleView .react-calendar__viewContainer) {
  display: flex;
  margin: -0.5em;
}
:global(.react-calendar--doubleView .react-calendar__viewContainer > *) {
  width: 50%;
  margin: 0.5em;
}
:global(.react-calendar),
:global(.react-calendar *),
:global(.react-calendar *:before),
:global(.react-calendar *:after) {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
:global(.react-calendar button) {
  margin: 0;
  border: 0;
  outline: none;
  font-weight: 600;
}
:global(.react-calendar button:enabled:hover) {
  cursor: pointer;
}

/* --- Top of Calendar --- */
:global(.react-calendar__navigation) {
  display: flex;
  height: 44px;
}
:global(.react-calendar__navigation button) {
  min-width: 44px;
  background: none;
  color: #536f85;
}
:global(.react-calendar__navigation__label) {
  color: #294b67 !important;
  font-weight: 600;
  font-size: 18px;
}
:global(.react-calendar__navigation button:disabled) {
  background: none;
  color: #94a5ab;
}
:global(.react-calendar__navigation button:enabled:hover),
:global(.react-calendar__navigation button:enabled:focus) {
  background-color: #e7eef0;
  border-radius: 5px;
}
:global(.react-calendar__month-view__weekdays) {
  text-align: center;
  text-transform: uppercase;
  font-size: 0.8em;
  text-decoration: none;
  color: #536f85;
}
:global(.react-calendar__month-view__weekdays__weekday) {
  padding: 0.5em;
}
:global(.react-calendar__month-view__weekNumbers .react-calendar__tile) {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}

/* --- Weekends --- */
:global(.react-calendar__month-view__days__day--weekend) {
  color: #294b67;
}
:global(.react-calendar__month-view__days__day--weekend:disabled) {
  color: #94a5ab;
}

:global(.react-calendar__month-view__days__day--neighboringMonth) {
  color: #94a5ab;
}

/* --- Days --- */
:global(.react-calendar__year-view .react-calendar__tile),
:global(.react-calendar__decade-view .react-calendar__tile),
:global(.react-calendar__century-view .react-calendar__tile) {
  padding: 2em 0.5em;
}
:global(.react-calendar__tile) {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
  color: #294b67;
  font-size: 0.9rem;
}
/* --- Unavailable Day --- */
:global(.react-calendar__tile:disabled) {
  color: #94a5ab;
}
/* Day Hover */
:global(.react-calendar__tile:enabled:hover),
:global(.react-calendar__tile:enabled:focus) {
  background-color: #e7eef0;
  color: #e5665d;
  border-radius: 5px;
}
/* Today */
:global(.react-calendar__tile--now) {
  color: #e5665d;
}
:global(.react-calendar__tile--now:enabled:hover),
:global(.react-calendar__tile--now:enabled:focus) {
  color: #e5665d;
}

/* --- Month Picker --- */
:global(.react-calendar__tile--hasActive) {
  background: #e5665d;
  color: #fff;
  border-radius: 5px;
}
:global(.react-calendar__tile--hasActive:enabled:hover),
:global(.react-calendar__tile--hasActive:enabled:focus) {
  background: #e5665d;
}
:global(.react-calendar__tile--active) {
  background: #e5665d;
  color: white !important;
  border-radius: 5px;
}
:global(.react-calendar__tile--active:enabled:hover),
:global(.react-calendar__tile--active:enabled:focus) {
  background: #e5665d;
}
:global(.react-calendar--selectRange .react-calendar__tile--hover) {
  background-color: #e7eef0;
}
/* --- Day of Week overrides --- */
:global(abbr) {
  text-decoration: none !important;
}
