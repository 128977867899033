.clinicList {
  padding: 70px 20px 20px;
  background: #f6f6f6;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
}

.clinicListItem {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.clinicDetails {
  flex-grow: 1;
}

.clinicListButtonWrap {
  margin: 10px 0 20px;
}

/* === No Clinics === */
.clinicListNone {
  text-align: center;
}
