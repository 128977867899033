.modalVeil {
  position: fixed;
  z-index: 100;
  top: 0px;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgb(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity var(--animationSubtle), transform var(--animationSubtle);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
}

.modalBox .loading p {
  margin: 5px 0 0;
}
.modalBox {
  background: #fff;
  border-radius: 20px;
  padding: 32px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 20px;
  transform: translateY(-30px);
  transition: opacity var(--animationSubtle), transform var(--animationSubtle);
}

/* === Modal Time === */
.modalTime h5 {
  margin: 5px 0 20px;
}

/* Show State */
.modalShow {
  visibility: visible;
}
.modaVeil.modalShow {
  opacity: 1;
}
.modalShow {
  transform: translateY(0px);
  opacity: 1;
}

/* Title */
.modalBox h5 {
  color: #294b67;
  margin: 0;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.2;
}

/* Text */
.modalBox p {
  font-size: 0.9rem;
  color: #536f85;
  margin: 18px 0 20px;
  line-height: 1.5;
}
.modalBox p b {
  font-weight: 700;
}

/* Subtext on Loader */
.modalBox small {
  font-size: 0.8re;
  color: #94a5ab;
}

/* Time Modal Tweaks */
.modalTime h5 {
  margin: 10px auto 20px;
}

/* ModalAllBills */
.allBillsContainer {
  padding: 12px 10px 12px 0;
  border-bottom: 1px solid #ececec;
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
}

.allBillsItem {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  padding: 0 0.75rem;
}

.allBillsFooter {
  margin-top: 0.75rem;
  font-weight: bold;
  font-weight: 16px;
}

.infoSavedHeader {
  margin-bottom: 0.5rem;
}

.infoSavedContainer {
  margin: 0.5rem 0;
  padding: 1rem 0.5rem 0.5rem;
}

/* === Booking Found === */
.modalBookingFound h5 {
  margin: 32px 0px 14px;
}
.modalBookingFound p {
  margin: 0 0 32px;
  font-size: 1rem;
  color: var(--main);
}
