.payBill img {
  max-width: 100%;
}
.payBillInner {
  padding: 20px;
}
.payBill h1 {
  margin: 0 0 20px;
}

.payBillLogIn {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.payBillLogIn p {
  margin: 0;
}

.pay {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: var(--background2);
  border-radius: 10px;
  padding: 20px;
}

.showAllBillsButton {
  font-weight: 500;
  font-size: 14px;
  display: flex;
}
.blueChevron {
  height: 11px;
  width: 14px;
  margin-left: 5px;
}

.amount {
  text-align: center;
}
.amount cite {
  font-style: normal;
  color: #94a5ab;
  font-weight: 600;
  font-size: 0.8rem;
  text-transform: uppercase;
}
.amount h2 {
  font-size: 2.5rem;
  font-weight: 500;
  margin: 10px 0 20px;
}
.amount em {
  font-size: 0.8rem;
  display: block;
  margin: 0 0 25px;
  color: var(--gray3);
}
