/* === Appointment Details === */
.info {
  background: #e7eef0;
  display: flex;
  flex-direction: row;
  padding: 15px 20px;
  gap: 30px;
  align-items: center;
}
.info > div {
  flex-grow: 1;
}
.info p {
  color: #536f85;
  font-size: 14px;
  font-weight: 600;
  margin: 10px 0 5px;
}
.info h3 {
  color: #294b67;
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 20px;
}

/* === Info Notice === */
.notice {
  background: #e7eef0;
  border-radius: 5px;
  padding: 12px 15px;
  display: flex;
  flex-direction: row;
  gap: 7px;
  align-items: start;
  color: #294b67;
  margin: 15px 0px;
  font-size: 12px;
}

/* === Form === */
.formWrap {
  padding: 20px;
}

.legalWrap {
  margin: 20px 0px;
}

/* === How to pay === */
.howToPay {
  margin: 30px 0 0;
}
.howToPay p {
  font-size: 14px;
}

.payButton {
  margin: 20px 0px;
}

/* === Advantage member === */
.advantageMember {
  margin: 30px 0 0;
}

/* === Self-pay total === */
.selfPayTotal {
  background: #e7eef0;
  border-radius: 5px;
  padding: 20px;
  margin: 20px 0 0;
  text-align: center;
}
.selfPayTotal h2 {
  border-bottom: 1px solid #d4dbe0;
  padding: 0 0 10px;
  margin: 0 0 10px;
}
.selfPayTotal small {
  font-size: 11px;
  color: #94a5ab;
}
