.finish {
  color: #294b67;
  background: #f6f6f6;
  padding: 0 0 50px;
}
.finish h1 {
  margin: 20px 0 0;
  font-size: 1.9rem;
}
.topBox {
  background: #e7eef0;
  padding: 30px;
  text-align: center;
}

.fancyBar {
  background: #e5665d;
  margin: 20px auto;
  text-indent: -9999px;
  width: 32px;
  height: 2px;
  border-radius: 2px;
}
.topBox p {
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
}
h2 {
  font-size: 24px;
  margin: 20px 0px;
  font-weight: 600;
}
h3 {
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 15px;
}
.buttons {
  display: flex;
  flex-direction: row;
  gap: 40px;
  justify-content: center;
  margin: 30px 0 0;
}
.buttons button {
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-decoration: none;
  font-size: 14px;
  color: #294b67;
  align-items: center;
}
.buttons button span {
  transition: scale 250ms;
  scale: 1;
}
.buttons button:hover span {
  scale: 1.15;
}
.buttons cite {
  font-style: normal;
}

/* === Tips === */
.tips {
  padding: 0px 20px;
}

/* === Next Steps === */
.nextSteps {
  padding: 20px;
  background: #f6f6f6;
  display: flex;
  flex-direction: column;
}

/* === Book for Someone Else === */
.someoneElse {
  padding: 20px;
  background: #e7eef0;
  margin: 0px 20px;
}
.someoneElse h3 {
  font-size: 1rem;
}
.someoneElse p {
  color: #536f85;
  font-size: 0.8rem;
  line-height: 1.5;
}
.someoneElse button {
  background: none;
  border: none;
  padding: 0;
  color: #007780;
  font-size: 0.9rem;
  font-weight: 700;
  text-decoration: none;
}
