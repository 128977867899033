.clinicDetails {
  position: relative;
}
.cardVeil {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 12;
  pointer-events: all;
}
.cardVeilHide {
  pointer-events: none;
}
.cardWrap {
  position: fixed;
  left: 0;
  right: 0;
  bottom: -20px; /* Must match the padding or else it peeks into the viewport */
  z-index: 13;
  display: flex;
  justify-content: center;
}
.card {
  flex-grow: 1;
  background: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transform: translateY(50%);
  transition: transform 300ms ease-in-out;
  padding: 20px;
  max-width: 460px;
}
.show .card {
  transform: translateY(0px);
}
.clinicDetails .buttons {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 0px 20px;
}

/* === Full Clinic Detail  === */
.detailsFull {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 14;
  background: #fff;
  display: flex;
  flex-direction: column;
}
.detailsFullWrap {
  padding: 20px;
  flex-grow: 1;
  overflow-y: scroll;
}
.detailsFull .buttons {
  margin: 20px;
}
