.downloadAppCard {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background: rgba(41, 75, 103, 0.2);
  backdrop-filter: blur(4px);
  pointer-events: all;
  z-index: 10;
}
.downloadAppCard .cardWrap {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 13;
  display: flex;
  justify-content: center;
}
.downloadAppCard .card {
  height: 330px;
  background: #f6f6f6;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 30px;
  text-align: center;
  max-width: 460px;
  min-width: 320px;
}

.downloadAppCardItem p {
  font-size: 1.1rem;
  line-height: 1.5;
  margin: 20px 10px 40px;
}

.downloadAppCard .card .buttons {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
}
