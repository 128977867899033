.summary {
  margin: 0 0 20px;
}
.summary > div {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.summary h2 {
  flex-grow: 1;
  text-align: left;
  margin: 10px 0;
}
.summary ol {
  list-style: none;
  margin: 0 0 30px;
  padding: 0;
}
.summary li {
  display: flex;
  font-size: 0.9rem;
  border-bottom: 1px solid var(--border);
  padding: 15px 0px;
  align-items: center;
}
.summary li span {
  flex-grow: 1;
  text-align: left;
  color: #536f85;
}
.summary li b {
  font-weight: 700;
  color: #294b67;
}
.summary li img {
  border-radius: 5px;
}
