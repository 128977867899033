.clinicServices {
  padding: 0px 0px 20px;
  border-bottom: 1px solid #d4dbe0;
  margin-bottom: 20px;
}
.clinicServices h5 {
  color: #294b67;
  font-weight: 600;
  font-size: 18px;
  margin: 0;
}
.clinicServices ul {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 10px 0;
  padding: 0;
}
.clinicServices li {
  display: flex;
  font-size: 13px;
  color: #536f85;
  gap: 10px;
  align-items: center;
  flex-basis: 45%;
}
.clinicServices li img {
  width: 30px;
  height: 30px;
}
.clinicServices li span {
  flex-grow: 1;
}
