.marker {
  background: #e5665d;
  border-radius: 30px;
  padding: 8px 15px;
  margin-left: -55px;
  margin-top: -65px;
  cursor: pointer;
  position: relative;
}
.marker:active {
  opacity: 0.8;
}
.marker button {
  background: none;
  border: none;
  cursor: pointer;
}
.marker p {
  font-size: 10px;
  font-family: 'PublicSans-Medium';
  color: #fff;
  margin: 0;
}
.marker h6 {
  font-size: 12px;
  font-family: 'PublicSans-Bold';
  color: #fff;
  margin: 3px 0 0;
}
.marker span:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 50%;
  margin-left: -13px;
  bottom: -13px;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-top: 13px solid #e5665d;
  cursor: pointer;
}

/* === Map Wrap === */
.mapWrap {
  position: relative;
}
/* === Walk-ins Message === */
.mapWalkIns {
  position: absolute;
  left: 10px;
  bottom: 25px;
}
.mapWalkIns p {
  font-size: 0.8rem;
  font-weight: 700;
  background: #fff;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 5px;
}
.mapWalkIns span {
  padding: 2px 0 0;
}
