/* === Book Start === */
.start {
  display: flex;
  height: 100dvh;
  flex-direction: column;
}
.wrap {
  position: relative;
  flex-grow: 1;
}
/* .drawer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 12;
} */

.button {
  margin: 20px 20px;
}

.show {
  opacity: 1;
  pointer-events: all;
}
.hide {
  opacity: 0;
  pointer-events: none;
}
