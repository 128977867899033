.home {
  background: #f6f6f6;
}
.home img {
  max-width: 100%;
}
.homeWrap {
  padding: 20px;
}

.home h1 {
  margin: 10px 0 30px;
  text-align: left;
}

.home ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.home li {
  padding: 0;
  margin: 0;
}

/* === Shared === */
.homeBigLink,
.homeLink {
  text-decoration: none;
  background: #fff;
  border-radius: 12px;
  display: flex;
}
.home h2 {
  margin: 0;
  font-weight: 600;
}
.home p {
  color: #536f85;
  font-size: 0.85rem;
}

/* === Big Home Link === */
.homeBigLink {
  flex-direction: column;
  text-align: center;
  padding: 20px;
  display: block;
}
.homeBigLink button {
  width: 100%;
}
.homeBigLink h2 {
  margin: 0 auto;
}
.homeBigLink p {
  margin: 3px auto 0;
}

/* === Regular Home Link === */
.homeLink {
  display: flex;
  flex-direction: row;
  gap: 15px;
  padding: 15px;
  align-items: center;
}

.homeLink section {
  flex-grow: 1;
}
.homeLink h2 {
  margin: 0 0 3px;
  text-align: left;
}
.homeLink p {
  margin: 0;
  text-align: left;
}

/* === Home Account === */
.homeAccount {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.homeAccount p {
  color: #294b67;
  text-align: center;
  font-size: 1.1rem;
  margin: 25px 0 10px;
}
.link {
  color: #007780;
}

/* === Upcoming Visits === */
.visits h2 {
  font-family: 'Vollkorn';
  margin: 0 0 15px;
}
.visits ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.visits li {
  margin: 0;
  padding: 0;
}
.visits .wrap {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  padding: 15px 20px;
  background: var(--background3);
  border-radius: 10px;
  font-size: 1rem;
  overflow: visible;
}
.visits li section {
  flex-grow: 1;
  text-align: left;
  color: var(--main);
}

.wrap h3 {
  font-weight: 500;
  font-size: 1rem;
  margin: 0;
}
.wrap p {
  margin: 5px 0px;
}
.wrap b {
  font-weight: 600;
  font-size: 0.9rem;
}

.wrap b.isWalkIn {
  font-size: 0.85rem;
  white-space: nowrap;
}
