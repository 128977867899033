.container {
  padding: 20px;
  position: relative;
}

.buttonWrapper {
  padding: 20px 20px 40px 20px;
}

.dashedImageContainer {
  border: dashed 2px #d9d9d9;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-bottom: 20px;
}

.dashedImageContainer img {
  object-fit: cover;
  overflow: hidden;
}