ul.checklist {
  list-style: none;
  margin: 0;
  padding: 0;
}
ul.checklist li {
  display: flex;
  align-items: start;
  gap: 10px;
}
ul.checklist li p {
  margin: 3px 0px;
  line-height: 1.2;
}
