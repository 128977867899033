.wrap {
  position: relative;
  height: 72px;
}
.inputWrap {
  height: 72px;
  margin: 10px 0px;
}
/* Fancy animated label */
.label {
  position: absolute;
  left: 15px;
  top: 4px;
  z-index: 2;
  color: #536f85;
  font-size: 0.8rem;
  padding: 0px 5px;
  background: linear-gradient(
    to top,
    #fff 0%,
    #fff 50%,
    #f6f6f6 50%,
    #f6f6f6 100%
  );
  transform: translateY(10px);
  transition: transform 250ms, opacity 250ms;
  opacity: 0;
}
.label.focused {
  transform: translateY(0px);
  outline: none;
  border: none;
  opacity: 1;
}
.label.disabledFocused {
  background: linear-gradient(
    to top,
    #eaecef 0%,
    #eaecef 50%,
    #f6f6f6 50%,
    #f6f6f6 100%
  );
}
/* Optional */
.label em {
  font-style: normal;
  color: #94a5ab;
  padding-left: 5px;
}

/* === Input === */
input {
  background: none;
  border: none;
  width: 100%;
  color: #294b67;
  padding: 14px 17px;
  outline: none;
  font-size: 1rem;
}
.input {
  background: #fff;
  border: 2px solid #d4dbe0;
  border-radius: 5px;
  display: flex;
  gap: 0px;
  position: absolute;
  left: 0;
  right: 0;
  top: 12px;
}
/* State */
.focused {
  border: 2px solid #86b7fe;
}
.disabled {
  background: #eaecef;
}
.disabled input {
  color: #94a5ab;
}
.signature input {
  font-family: 'MeieScript';
  font-size: 1.5rem;
  padding: 10px;
}
.sideWrap {
  width: 30px;
  height: 24px;
  position: relative;
}
.sideWrap b {
  position: absolute;
}
.hide {
  opacity: 0;
}
/* Icon */
span.icon {
  padding: 13px 0 0 15px;
}
/* Error */
.error .input {
  border: 2px solid #e5665d;
}
.inputWrap p.inputError {
  background: #e5665d;
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.7rem;

  position: absolute;
  margin: 0;
  bottom: 2px;
  right: 10px;
  padding: 3px 7px;
  border-radius: 5px;
  display: inline-block;
  transform: translateY(10px);
  transition: transform 250ms, opacity 250ms;
  opacity: 0;
  font-weight: 700;
}
.inputWrap p.inputError.error {
  opacity: 1;
  transform: translateY(0px);
}
