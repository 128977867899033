.bookInfo {
  display: flex;
  flex-direction: column;
}

/* === Clinic Details === */
.bookInfoClinic {
  background: #e7eef0;
  display: flex;
  flex-direction: row;
  padding: 15px 20px;
  gap: 30px;
  align-items: center;
}
.bookInfoClinic > div {
  flex-grow: 1;
}
.bookInfoClinic p {
  color: #536f85;
  font-size: 14px;
  font-weight: 600;
  margin: 10px 0 5px;
}
.bookInfoClinic h3 {
  color: #294b67;
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 20px;
}

/* === Book Info Form === */
.bookInfoForm {
  background: #f6f6f6;
  padding: 20px;
  color: #294b67;
}
.bookInfoForm h4 {
  margin: 10px 0px;
  font-size: 1.2rem;
  font-weight: 600;
}
.bookInfoForm p {
  margin: 0;
  line-height: 1.5;
}
.bookInfoFormButton {
  margin: 15px 0px;
}
