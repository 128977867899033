.clinicInfo {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}
.clinicTitle {
  flex-grow: 1;
  position: relative;
}
.clinicTitleWrap {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.clinicTitleWrap h5 {
  flex-grow: 1;
  margin: 0;
}
.clinicTitleWrap button {
  width: 30px;
  height: 30px;
  background: #000;
  border: none;
  padding: 0;
}

.clinicInfo h5 {
  color: #294b67;
  font-weight: 600;
  font-size: 18px;
}

/* Favorites Star */
.favoriteStarButton {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

/* .star {
  visibility: hidden;
  font-size: 30px;
  cursor: pointer;
}
.star:before {
  content: '\\2606';
  visibility: visible;
  color: #94a5ab;
}
.star:checked:before {
  content: '\\2605';
  color: #e5665d;
}
.starChecked:before {
  content: '\\2605';
  color: #e5665d;
} */
