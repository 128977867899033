.selector {
  list-style: none;
  margin: 0;
  padding: 0px;
  display: inline-flex;
  flex-direction: row;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  max-width: 100%;
  width: fit-content;
}
.selector.fullWdith {
  width: 100%;
}
.selector li {
  cursor: pointer;
  padding: 11px 16px;
  color: #536f85;
  font-weight: 700;
  font-size: 0.7rem;
  text-transform: uppercase;
  display: inline-block;
  letter-spacing: 1.2;
  text-wrap: nowrap;
  text-align: center;
}
.selector li.selected {
  background: #e5665d;
  color: #fff;
}
.selector li:active {
  opacity: 0.7;
}

.selector li:focus-visible {
  border: solid 1px #e5665d;
  outline: #e5665d auto 5px;
  border-radius: 0;
}

.selector li:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.selector li:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.inline {
  display: inline-block;
}

/* === Individual Button Styles === */
.buttonStyle.selector {
  background: none;
  gap: 10px;
}
.buttonStyle.selector li {
  border: 1px solid #d4dbe0;
  /* border-radius: 5px; */
  text-transform: none;
}
