ul.documentList {
  list-style: none;
  margin: 0 0 20px;
  padding: 0;

  border-radius: 5px;
}
ul.documentList li {
  margin: 0;
  padding: 0;
  border-top: 1px solid #d4dbe0;
  display: flex;
  gap: 20px;
  align-items: start;
}
ul.documentList li:last-child {
  border-bottom: 1px solid #d4dbe0;
}
ul.documentListNoBorders li,
ul.documentListNoBorders li:last-child {
  border: none;
}
.pdf,
.download {
  min-width: 24px;
}

ul.documentList li.indent {
  padding-left: 20px;
  padding-right: 20px;
}

.documentList a {
  display: flex;
  flex-grow: 1;
  gap: 10px;
  padding: 20px 0px;
  text-decoration: none;
  color: #294b67;
}
.documentList a:active {
  opacity: 0.7;
}
.documentList a p {
  margin: 0;
  flex-grow: 1;
}
.documentList a p small {
  display: block;
  font-size: 0.8rem;
}

/* Inner Link Content */
.content {
  padding: 20px 0px;
  display: flex;
  gap: 10px;
}
.content p {
  flex-grow: 1;
  margin: 0;
}
.content a {
  text-decoration: underline;
  font-weight: 700;
  color: #007780;
  display: inline;
  padding: 0;
  line-height: 1.5;
}

/* Switch padding */
.switchWrap {
  padding: 25px 0 0;
}
