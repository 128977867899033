.redirectText {
  padding: 30px;
  text-align: center;
}

.nullState {
  padding: 50px 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.virtualCareLocation {
  padding: 20px;
}

.virtualCareLocation .wrap {
  padding: 10px 0px;
}
