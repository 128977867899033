.swiper {
  position: relative;
}
.swiper button {
  position: absolute;
  appearance: none;
  z-index: 2;
  top: 50%;
  margin-top: -25px;
  background: none;
  width: 50px;
  height: 50px;
  border: none;
  cursor: pointer;
}
.swiper button:hover {
}
.swiper button svg {
  position: absolute;
  top: 17px;
}
.swiper button:active {
  opacity: 0.7;
}
/* Left/Right Buttons */
.prev {
  left: 20px;
}
.prev svg {
  left: 19px;
}
.next {
  right: 20px;
}
.next svg {
  right: 19px;
}
