/* === Virtual Care Practice === */
.virtualCarePractice {
  background: #e7eef0;
  padding: 20px;
  border-bottom: 1px solid #d4dbe0;
}
.virtualCarePractice h1 {
  font-family: 'PublicSans';
  font-size: 24px;
  font-weight: 500;
  margin: 0 0 10px;
}
.virtualCarePractice h3 {
  font-size: 16px;
  margin: 0 0 20px;
  font-weight: 500;
}

.virtualCarePracticeDetails {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.virtualCarePracticeDetails p {
  color: #294b67;
  font-size: 13px;
  line-height: 1.3;
  display: flex;
  flex-direction: row;
  gap: 3px;
  margin: 0px;
}

.virtualCarePracticeDetails i {
  width: 20px;
}
.virtualCarePracticeDetails span {
  flex-grow: 1;
  padding: 1px 0 0;
}
.virtualCarePracticeDetails p b {
  font-weight: 700;
}
.virtualCarePracticeDetails p em {
  font-style: normal;
  color: #349d47;
  font-weight: 700;
}
.virtualCarePracticeDetails em.closed {
  color: #e6665d;
}
.virtualCarePracticeDetails p strong {
  font-weight: 700;
  display: block;
}
