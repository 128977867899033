.mapOptions {
  display: flex;
  position: absolute;
  z-index: 3;
  justify-content: space-between;
  padding: 10px;
  left: 0px;
  right: 0px;
  height: 32px;
  background: #fff;
  border-bottom: 1px solid var(--border);
}
.mapOptions.clearBackground {
  background: none;
}

.mapOptions > div {
  display: flex;
}

/* === Toggle Map/List === */
.toggleButtons {
  display: flex;
}
.toggleButtons button {
  display: flex;
  align-items: center;
  gap: 3px;
  color: var(--gray3);
  border: 1px solid #ced4da;
  font-weight: 600;
  font-size: 0.9rem;
  padding: 5px 10px;
  cursor: pointer;
  background: #fff;
}
.toggleButtons button:active {
  opacity: 0.7;
}
.toggleButtons > button:first-child {
  border-radius: 5px 0px 0px 5px;
}
.toggleButtons > button:last-child {
  border-radius: 0px 5px 5px 0px;
}
.toggleButtons span {
  line-height: 1;
  height: 20px;
}
.toggleButtons p {
  margin: 0;
}
.toggleButtons button.selected {
  background: var(--red);
  border-color: var(--red);
}
.toggleButtons button.selected p {
  color: var(--white);
}

/* === Filter Button === */
.filter button {
  color: #536f85;
  border-radius: 5px !important;
}

/* === Zoom to See Availability === */
.zoomToSee {
  color: var(--main);
  font-size: 0.9rem;
  font-weight: 700;
  background: rgba(255, 255, 255, 1);
  border-radius: 5px;
  padding: 5px 15px;
  display: flex;
  align-items: center;
}
