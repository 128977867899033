/* === Colors === */
:root {
  /* Blue/Gray */
  --bg800: #294b67;
  --bg700: #536f85;
  --bg500: #94a5ab;
  --bg300: #d4dbe0;
  --bg200: #e7eef0;
  --bg100: #f6f6f6;
  --bg000: #fff;

  /* Red */
  --red400: #a73931;
  --red300: #e5665d;

  --red200: #f3bfbb;
  /* Teal */
  --teal400: #007780;
  --teal300: #008e9a;
  --teal200: #72b7cc;
  --teal100: #cce8e9;
  /* Green */

  --background1: #fff;
  --background2: #f6f6f6;
  --background3: #e7eef0;
  --border: #d4dbe0;

  --animationSubtle: 250ms;

  /* Named colors for convenience */
  --main: #294b67;
  --red: #e5665d;
  --green: #349d47;
  --white: #fff;
  --gray1: #536f85;
  --gray2: #007780;
  --gray3: #95a4ab;

  /* === Carousel Component Overrides === */
  --swiper-pagination-color: #008e9a;
  --swiper-pagination-bullet-inactive-color: var(--border);
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-size: 10px;
  --swiper-pagination-bullet-horizontal-gap: 4px;
  --swiper-pagination-bottom: 0px;
}

/* === Fonts === */
@font-face {
  font-family: 'PublicSans';
  src: url('./PublicSans-Medium.woff2') format('woff2');
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: 'PublicSans';
  src: url('./PublicSans-SemiBold.woff2') format('woff2');
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: 'PublicSans';
  src: url('./PublicSans-Bold.woff2') format('woff2');
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: 'Vollkorn';
  src: url('./Vollkorn-SemiBold.woff2') format('woff2');
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: 'MeieScript';
  src: url('./MeieScript-Regular.woff2') format('woff2');
  font-style: normal;
  font-weight: 500;
}

/* === Basics === */
html {
  margin: 0;
  padding: 0;
  /* This is the base font size for the whole size and all `rem` values are relative to it */
  font-size: 16px;
}

body {
  line-height: 1;
  background: var(--background2);
  color: var(--bg800);
  font-family: 'PublicSans';
  font-weight: 500;
  margin: 0;
}

/* === Type Styles === */
h1 {
  font-family: 'Vollkorn';
  font-size: 2.2rem;
  color: var(--main);
}
h2 {
  font-family: 'PublicSans';
  font-size: 1.2rem;
  color: var(--main);
}

/* === Shared Form Styles === */
input,
select,
textarea {
  font-family: 'PublicSans';
  color: var(--main);
}

section {
  text-wrap: wrap;
}

::placeholder {
  color: var(--gray3);
}

.noTouchy {
  margin: 20px 0 0;
}

.middle {
  flex-grow: 1;
}

/* Remove border from Book Now map */
.gm-style iframe + div {
  border: none !important;
}
