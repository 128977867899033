.payBill img {
  max-width: 100%;
}
.payBillInner {
  padding: 20px;
}
.payBill h1 {
  margin: 0 0 20px;
}

.iframeContainer {
  display: flex;
  height: 500px;
}
.iframeContainer iframe {
  width: 100%;
}
.buttonContainer {
  display: flex;
  justify-content: end;
  margin: 45px 0;
}
