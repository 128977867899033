.navEdit {
  align-items: center;
  background: #fff;
  border-bottom: 1px solid #d4dbe0;
  display: flex;
  flex-direction: row;
  height: 50px;
  justify-content: space-between;
  padding: 5px 10px;
}

.navWithProgress {
  border: none;
}

.navEdit h5 {
  color: #294b67;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}

.leftBtn,
.rightBtn {
  width: 55px;
}

.leftBtn button,
.rightBtn button {
  color: #294b67;
  font-size: 14px;
  font-weight: 700;
}
