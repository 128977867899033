.textareaWrap {
}
.textarea {
  background: #fff;
  border: 2px solid #d4dbe0;
  border-radius: 5px;
}
.textarea textarea {
  border: none;
  background: none;
  color: #294b67;
  width: 100%;
  outline: none;
  padding: 12px 15px;
  font-size: 1rem;
  box-sizing: border-box;
  min-height: 80px;
}

/* Error */
.textarea.error {
  border: 2px solid #e5665d;
}
p.textareaError {
  opacity: 0;
  margin: 0;
  position: absolute;
  bottom: 0px;
  right: 10px;
  padding: 1px 7px;
  border-radius: 5px;
  color: rgba(255, 255, 255, 0.8);
  background: #e5665d;
  font-size: 11px;
  font-style: italic;
  display: inline-block;
  transform: translateY(10px);
  transition: transform 250ms, opacity 250ms;
  opacity: 0;
}

p.textareaError.error {
  opacity: 1;
  transform: translateY(0px);
}
