/* === Account List === */
.account {
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
}

/* === /// === */
.accountLogin {
  display: flex;
  padding: 20px;
  flex-direction: column;
  text-align: left;
  font-family: 'PublicSans-Medium';
  background: #f6f6f6;
  justify-content: space-between;
  min-height: 700px;
}

.accountLoginLogo {
  text-align: center;
}

.account h2 {
  color: #294b67;
  font-family: 'Vollkorn-SemiBold';
  font-size: 30px;
  margin: 25px 0 10px;
}
.account p {
  font-size: 0.9rem;
  color: #294b67;
  line-height: 1.3;
}

/* === Forms === */
.account form {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* === Remember Me === */
.rememberMe {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
}
.rememberMe p {
  color: #294b67;
  font-size: 16px;
}

/* === Forgot Password === */
.forgot {
  text-align: center;
}
.forgot a {
  font-size: 14px;
  font-family: 'PublicSans-SemiBold';
  color: #007780;
  text-decoration: none;
}

/* === Edit Account Profile === */
.accountEdit {
  background: #f6f6f6;
}
