.clinicDetailsStatus {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.clinicDetailsStatus p {
  color: #294b67;
  font-size: 13px;
  line-height: 1.3;
  display: flex;
  flex-direction: row;
  gap: 3px;
  margin: 0px;
}
.clinicDetailsStatus i {
  width: 20px;
}
.clinicDetailsStatus span {
  flex-grow: 1;
  padding: 1px 0 0;
}
.clinicDetailsStatus p b {
  font-weight: 700;
}
.clinicDetailsStatus p em {
  font-style: normal;
  color: #349d47;
  font-weight: 700;
}
.clinicDetailsStatus em.closed {
  color: #e6665d;
}
.clinicDetailsStatus p strong {
  font-weight: 700;
  display: block;
}
.noClinicSelected {
  color: #294b67;
}
