.primary {
  display: flex;
  background: var(--teal200);
  align-items: center;
  padding: 10px 15px;
}
.primary h2 {
  flex-grow: 1;
  margin: 0;
  text-align: center;
}
