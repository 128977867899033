.bookPatient {
  background: #f6f6f6;
  padding: 20px;
  color: #294b67;
}
.bookPatient h4 {
  color: #294b67;
  font-weight: 600;
  font-size: 18px;
  margin: 10px 0 20px;
}

/* === Patient List === */
.bookPatient ol {
  list-style: none;
  margin: 0 0 40px;
  padding: 0;
}
.bookPatient ol li {
  margin: 0 0 10px;
  padding: 0;
}
.bookPatient li button {
  border-radius: 5px;
  display: block;
  width: 100%;
  cursor: pointer;
  text-align: center;
  border: 2px solid #d4dbe0;
  font-weight: 600;
  color: #294b67;
  padding: 15px 20px;
  font-size: 15px;
  outline: none;
}
.bookPatient li button em {
  color: #94a5ab;
  font-style: normal;
}
.bookPatient li.selected button {
  background: #e5665d;
  color: #ffffff;
  border: 2px solid #e5665d;
}
.bookPatient li.selected button em {
  color: rgba(255, 255, 255, 0.7);
}

/* === Patient (Dependant Form) === */
.bookPatientForm {
  margin: 0 0 30px;
}
.bookPatientForm h4 {
  margin: 10px 0px;
}
.bookPatientFormSelect {
  margin: 25px 0 0;
}
.bookPatientFormSelect p {
  line-height: 1.5;
  font-size: 0.8rem;
  padding: 0 20px;
}

/* === Reason for Visit === */
.bookPatientReason {
  margin: 20px 0px;
}
.bookPatientReason h4 {
  margin: 0 0 10px;
}

/* === NextCare Employee Check === */
.bookPatientEmployee > p,
.bookPatientEmployeeYes p:first-child {
  line-height: 1.5;
  color: #294b67;
  font-size: 14px;
}
.bookPatientEmployeeYes {
  margin: 20px 0 0;
}
.bookPatientEmployeeYes p {
  margin: 0;
}

/* === Button === */
.bookPatientButton {
  margin: 30px 0px;
}
