/* === Navbar === */
.nav {
  height: 50px;
  min-height: 50px;
  border-bottom: 1px solid #d4dbe0;
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  background: #fff;
}
.navWithProgress {
  border: none;
}
.bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-grow: 1;
  gap: 0px;
}
.bar h5 {
  margin: 0 auto;
  font-size: 20px;
  color: #294b67;
  font-weight: 600;
}

.visible,
.invisible {
  width: 40px;
}
.visible {
  visibility: visible;
}
.invisible {
  visibility: hidden;
}

/* === Progress Bar === */
.progress {
  background: #e7eef0;
  height: 6px;
  width: 100%;
  border-radius: 6px;
}
.progress span {
  display: inline-block;
  text-indent: -9999px;
  height: 6px;
  background: #e5665d;
  border-radius: 6px;
}

/* === Skip Button === */
.skip button {
  font-weight: 700;
  color: #294b67;
  font-size: 14px;
}
