.link p {
  padding: 15px 20px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--background3);
  margin: 0;
  align-items: center;
}
.link:last-child p {
  border: none;
}
.link cite {
  font-style: normal;
  flex-grow: 1;
  text-align: left;
  font-size: 1.1rem;
}
.link span {
  padding-top: 3px;
}
