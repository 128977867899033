.mapSearch {
  background: #fff;
  border: 2px solid #d4dbe0;
  border-radius: 25px;
  display: flex;
  padding: 3px 4px;
  width: 100%;
  gap: 5px;
}
.locationIcon {
  padding: 0 0 0px 5px;
  display: flex;
}
.mapSearch input {
  border: none;
  background: none;
  flex-grow: 1;
  font-size: 15px;
  outline: none;
  color: #294b67;
  padding: 8px 10px;
}
.searchButton {
  outline: none;
  background: #e5665d;
  border-radius: 30px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
