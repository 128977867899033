.button {
  cursor: pointer;
  appearance: none;
  border: none;
  cursor: pointer;
  font-family: 'PublicSans';
  font-size: 1.1rem;
  text-wrap: nowrap;
}
.button:active {
  opacity: 0.7;
}
.button:focus-visible {
  outline: #e5665d auto 1px;
}
/* Disabled */
.button.primary.disabled,
.button.secondary.disabled {
  background: #e7eef0;
  cursor: not-allowed;
  color: #94a5ab;
  opacity: 1;
  border: 2px solid #e7eef0;
}

/* Primary */
.button.primary,
.button.secondary {
  padding: 13px 20px;
  border-radius: 5px;
  border: 2px solid #e5665d;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 600;
  width: 100%;
}
.button.primary {
  background: #e5665d;
  color: #fff;
}

/* Secondary */
.button.secondary {
  background: none;
  color: #e5665d;
}

/* Tertiary (link style red) */
.button.tertiary {
  background: none;
  border: none;
  color: #e5665d;
}

/* Tertiary (link style gray) */
.button.quaternary {
  background: none;
  border: none;
  color: #007780;
}

.button.mini {
  font-size: 0.9rem;
  padding: 5px 15px;
  font-weight: 700;
}

/* Generic clickable button with children */
.children {
  border: none;
  background: none;
  padding: 0;
}

.inline {
  width: auto;
  padding: 0;
}
.block {
  width: 100% !important;
  display: block;
}
