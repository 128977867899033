.accountCreateMessage {
  display:flex;
  flex-direction:column;
  text-align:left;
  background:#F6F6F6;
}

.accountCreateMessage h3 {
  margin: 20px 0 0px;
}

.accountCreateMessage p {
  font-size:18px;
  color:#536F85;
  line-height:1.5;
}

.accountCreateMessage p span {
  font-weight:bold;
}

.accountCreateReview {
  display:flex;
  flex-direction:column;
  text-align:left;
  background:#F6F6F6;
  position: relative;
  flex: 1;
}

.formContainer {
  display:flex;
  flex-direction:column;
  background:#F6F6F6;
  position: relative;
  justify-content: space-between;
  height: auto;
  flex: 1;
}

.listItem {
  padding: 40px;
}

.accountCreateReviewMessage h3 {
  padding: 20px 20px 0 20px;
  line-height:1.5;
}

.accountCreateReviewMessage p {
  padding: 0 20px 0 20px;
  font-size:18px;
  color:#536F85;
  line-height:1.3;
}

.buttonRow {
  position: relative;
  display:flex;
  flex-direction: row;
  justify-content:space-between;
  gap: 20px;
  padding: 20px;
}

.buttonRow > div {
  width: 100%;
  bottom: 0;
  position: absolute;
}
