/* === Forms === */
.personal {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding: 20px;
}
.personal h2 {
  margin: 0;
}
.personal .wrap {
  padding: 10px 0px;
}
.personal .wrapFat {
  padding: 14px 0px;
}
.personal hr {
  border: none;
  border-top: 1px solid #d4dbe0;
  margin: 20px 0px;
  opacity: 1;
}

.account {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding: 20px;
}
.account h2 {
  margin: 0;
}
.account .wrap {
  padding: 10px 0px;
}
.account .wrapFat {
  padding: 14px 0px;
}
.account hr {
  border: none;
  border-top: 1px solid #d4dbe0;
  margin: 20px 0px;
  opacity: 1;
}

.account h3 {
  margin-top: 30px;
}

.buttonWrap {
  margin: 20px 0;
}

