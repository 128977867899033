.select {
  background: #fff;
  border: 2px solid #d4dbe0;
  border-radius: 5px;
  color: #294b67;
  position: relative;
  height: 46px;
  margin: 10px 0px;
}
.select select:focus {
  border: 1px solid #e5665d;
  border-radius: 5px;
  outline: #e5665d auto 1px;
}
.select select {
  background: none;
  border: none;
  padding: 13px 19px;
  appearance: none;
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  outline: none;
  z-index: 1;
  font-size: 1rem;
}
.select .icon {
  position: absolute;
  right: 10px;
  top: 15px;
  pointer-events: none;
}

/* Fancy label */
.select label {
  position: absolute;
  left: 15px;
  top: -9px;
  z-index: 2;
  color: #536f85;
  font-size: 0.8rem;
  padding: 0px 5px;
  background: linear-gradient(
    to top,
    #fff 0%,
    #fff 50%,
    #f6f6f6 50%,
    #f6f6f6 100%
  );
  opacity: 1;
}

/* Disabled */
.select.disabled label {
  background: linear-gradient(
    to top,
    #eaecef 0%,
    #eaecef 50%,
    #f6f6f6 50%,
    #f6f6f6 100%
  );
}
.disabled {
  background: #eaecef;
}
.disabled select {
  color: #94a5ab;
}
.disabled .icon {
  opacity: 0.4;
}

/* When no value selected, the default value should use light gray */
.noSelection select {
  color: var(--gray3);
}
