.clinicPinDistance {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.clinicPinDistance span {
  background: var(--background3);
  border-radius: 100%;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clinicPinDistance small {
  font-size: 11px;
  color: #536f85;
  text-align: center;
  text-wrap: nowrap;
}
