/* Clinic */
.clinicDistance {
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #d4dbe0;
  background-color: #e7eef0;
  cursor: pointer;
  display: flex;
}
.clinicDistanceCheckbox {
  flex: 0 0 10%;
}
.clinicDistanceInfo {
  flex: 0 0 80%;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
.clinicDistanceMilesContainer {
  flex: 0 0 10%;
}
.clinicDistanceMiles {
  font-size: 12px;
  font-weight: 600;
  color: #536f85;
}
.clinicDistanceAddress,
.clinicDistancePhone {
  font-weight: 500;
  color: #294b67;
  font-size: 16px;
}
.clinicDistanceTitle {
  color: #294b67;
  font-size: 18px;
  font-weight: 600;
}
.clinicDistancePhone img {
  height: 16px;
  width: 16px;
  margin-right: 8px;
}
.rightChevronIcon {
  padding-top: 6px;
  margin-right: 9px;
  float: right;
}

/* Null state */
.nullState {
  color: #294b67;
  font-size: 16px;
  margin: 60px auto;
  text-align: center;
  max-width: 400px;
}

/* Bottom section */
.findALocationContainer {
  margin-top: 12px;
  padding: 0 20px;
}

.findALocation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  width: 100%;
  margin-bottom: 1.25rem;
}

.info {
  background-color: #e7eef0;
  padding: 8px 20px 8px 40px;
  position: relative;
  color: #294b67;
}

.info.editMode {
  color: #94a5ab;
}

.infoIcon {
  height: 15px;
  width: 15px;
  margin-top: -2px;
  margin-right: 10px;
  position: absolute;
  top: 15px;
  left: 15px;
}
