/* === Book Info Login Promo === */
.loginBanner {
  background: #294b67;
  text-align: center;
  padding: 30px;
  color: #fff;
}
.loginBanner h2 {
  font-weight: 600;
  font-size: 1.2rem;
  margin: 0 0 10px;
  color: #fff;
}
.loginBanner p,
.loginBanner span {
  font-size: 0.9rem;
  margin: 0;
  font-weight: 700;
  color: #fff;
}
.loginBanner span {
  color: #008e9a;
  padding-right: 5px;
}
.loginBanner a:hover {
  color: #94a5ab;
}
.loginBanner a:active {
  opacity: 0.7;
}
