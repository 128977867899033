.downloadBanner {
  display: flex;
  align-items: center;
  background: #f6f6f6;
  padding: 10px 20px;
  line-height: 1;
}
.downloadBanner > div {
  flex-grow: 1;
  margin: -1px 10px 0px;
}
.downloadBanner strong {
  font-weight: normal;
  font-weight: 700;
  font-size: 13px;
}
.downloadBanner p {
  font-size: 11px;
  color: #536f85;
  margin: 0;
}
.downloadBanner a {
  text-decoration: none;
  background: #007aff;
  padding: 6px 12px;
  text-transform: uppercase;
  color: #fff;
  border-radius: 20px;
  font-size: 13px;
  font-weight: 600;
}
