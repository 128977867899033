.insurance {
  background: #e7eef0;
  border-radius: 5px;
  padding: 12px 15px;
  display: flex;
  flex-direction: row;
  gap: 7px;
  align-items: start;
  color: #294b67;
  margin: 15px 0px;
  font-size: 12px;
}

.insurance h6 {
  font-size: 0.8rem;
  font-weight: 700;
  margin: 2px 0 4px;
}
.insurance p {
  margin: 0;
  font-size: 0.8rem;
}
