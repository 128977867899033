.switchWrap {
  display: flex;
  gap: 15px;
  align-items: center;
}

/* Switch Label */
.switchWrap p {
  flex-grow: 1;
  margin: 0;
  cursor: pointer;
  padding: 0 0 3px;
}
.switchWrap p:active {
  opacity: 0.7;
}

/* === Switch HTML content when children attribute set === */
.switchChildren p {
  line-height: 1.35;
  color: #294b67;
  font-size: 14px;
}
.switchChildren a {
  color: #007780;
  font-weight: 600;
}

.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 14px;
}
/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d4dbe0;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: -1px;
  bottom: -3px;
  background-color: rgb(255, 255, 255);
  transition: 0.4s;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 4px;
}
input:checked + .slider {
  background-color: rgb(246, 158, 158);
}
input:focus + .slider {
  box-shadow: 0 0 1px;
}
input:checked + .slider:before {
  transform: translateX(20px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
  background-color: #fff;
}
input:checked + .slider.round:before {
  background-color: rgb(246, 109, 109);
}
