.paperworkWrap {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100dvh;
  position: relative;
}
.paperwork {
  flex-grow: 1;
  background: #f6f6f6;
  overflow-y: scroll;
}
.paperworkInner {
  padding: 0px 20px 20px;
}
.paperworkInner h2 {
  color: #294b67;
  font-size: 1.2rem;
  line-height: 1.5;
}
.paperworkInner h2 em {
  font-style: normal;
  color: #94a5ab;
}
.paperworkInner p {
  font-size: 0.9rem;
  color: #536f85;
  line-height: 1.5;
}
.fieldWrap {
  margin: 10px 0px 25px;
}
.fixedField {
  height: 52px;
  padding: 14px 0px;
}
.fieldWrapExtra {
  margin: 25px 0px;
}
.buttonWrap {
  padding: 10px 20px 20px;
  background: #f6f6f6;
  /* background: linear-gradient(
    to top,
    #f6f6f6 0%,
    #f6f6f6 80%,
    rgba(246, 246, 246, 1) 0%,
    rgba(246, 246, 246, 0) 100%
  ); */
}
.paperwork .select {
  margin: 20px 0px 30px;
}
.split {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.split > * {
  flex-basis: 50%;
}
.stateWrap {
  padding: 2px 0 0;
}
p.padme {
  margin-top: 20px;
}

/* Visibility */
.visible {
  visibility: visible;
}
.invisible {
  visibility: hidden;
}

/* === Insurance Cards === */
.insuranceSecondary h2 {
  margin: -10px 0 0;
}

/* === Summary === */
.same {
  font-size: 0.9rem;
  color: #536f85;
  padding: 10px;
}

.summaryForms p {
  font-size: 1rem;
}
.loading {
  display: flex;
  justify-content: center;
}
.legalAgreement {
  margin: 20px 0 0;
}

/* === Save Information / Create Account at end of paperwork === */
.saveInfo h1 {
  text-align: center;
  margin: 0;
  font-size: 2rem;
  line-height: 1.2;
}
.saveInfoHeading {
  background: #e7eef0;
  padding: 20px;
  border-bottom: 1px solid var(--border);
}
.saveInfoHeading p {
  color: #536f85;
  line-height: 1.5;
  text-align: center;
}
.saveInfoButtons {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.heightFields {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.label {
  font-size: 0.9rem;
  color: #536f85;
  margin: 30px 0 5px 0;
  line-height: 1.4rem;
}

.heightFields > div {
  flex-basis: 50%;
}

/* === Create Account === */
.createAccount {
  display: flex;
  justify-content: center;
  padding: 30px;
}
