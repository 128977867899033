.formContainer {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.subtitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20px;
  margin-top: 0;
}

.subtitle span {
  font-weight: 700;
}

.divider {
  border-bottom: 1px solid #d4dbe0;
  margin-bottom: 20px;
}

.wrap {
  height: 72px;
  margin: 10px 0px;
}
