.components {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.components h1 {
  margin: 30px 0px 0px;
}
.components h3 {
  margin: 20px 0px 0px;
}
.form {
  background: var(--background2);
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.flow {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.stack {
  display: flex;
  flex-direction: column;
}
