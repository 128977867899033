.notificationsBody {
  margin-bottom: 1.25rem;
  padding: 30px;
  position: relative;
}

.nullState {
  text-align: center;
}

.nullStateIcon {
  width: 68px;
  width: 58px;
  margin-top: 200px;
}

.nullSate p {
  margin: 10px 50px 200px 50px;
  color: #536f85;
  font-size: 16px;
}

.notificationsCard {
  z-index: 13;
  padding: 20px;
  background-color: #e7eef0;
  border-color: #d4dbe0;
  border-radius: 10px;
  margin-bottom: 25px;
  display: flex;
}

.notificationsCardBody {
  flex: 0 0 80%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.notificationsCardDismiss {
  flex: 0 0 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
}

.notificationsIcon {
  width: 46px;
  margin-right: 15px;
}

.notificationTitle {
  font-size: 18px;
  color: #294b67;
  margin-bottom: 0;
}

.time {
  color: #6ab7cf;
  font-size: 14px;
}

.notificationsMessage {
  color: #294b67;
  font-size: 14px;
  margin-bottom: 0;
  max-height: 40px;
  overflow: hidden;
  transition: max-height 1s ease-out;
}

.notificationsMessage.showMore {
  max-height: unset;
  transition: max-height 1s ease-in;
}

.notificationsToggle {
  font-size: 14px;
  -moz-transition: all 0.5s linear;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.notificationsToggle.down {
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.notificationsToggle:after {
  border-style: solid;
  border-width: 0 3px 3px 0;
  content: '';
  display: inline-block;
  height: 12px;
  width: 12px;
  transform: rotate(-135deg);
  cursor: pointer;
  color: #94a5ab;
  border-radius: 2px;
}

@media (max-width: 767px) {
  .notificationsToggle {
    position: absolute;
    top: 15px;
  }
  .notificationsDismiss {
    margin-top: 8px;
  }
}

.notificationsDismiss {
  font-size: 14px;
  color: #f3a8a2;
}
.notificationsDismiss:hover {
  color: #e08781;
}
